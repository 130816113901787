import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Checkbox, TablePagination, TableFooter } from "@mui/material";
import { TableToolbar } from './TableToolbar';
import { handleGetSubscriptions, selectState, setSelectedId, setPage, setRowsPerPage } from './subscriptionSlice';
import TablePaginationActions from './TablePaginationActions';
import styles from './Subscription.module.css';
import { centToDollar } from '../../app/utils';
import { StickyTableHead } from '../../helpers/GeneralComponent';

export function Subscription(props) {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const { id } = useParams();
  
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetSubscriptions(id));
    }
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetSubscriptions(id));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetSubscriptions(id));
  };

  return (
    <div className={styles.container}>
      {state.user && (
        <div className="mb-5">
          <h1 className="text-2xl font-bold">{`${state.user.firstName} ${state.user.lastName}`}</h1>
          <p className="text-slate-500 font-medium">{state.user.userName}</p>
        </div>
      )}
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-12 lg:col-span-9">
          <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
            <Table size="small">
              <StickyTableHead>
                <TableRow>
                  <TableCell colSpan={27} sx={{ padding: 0, borderBottom: 'none' }}>
                    <TableToolbar
                      numSelected={state.selectedId === null ? 0 : 1}
                      selectedVoucher={state.selectedId ? state.subscriptions.find(subscription => subscription.id === state.selectedId) : null}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                  <TableCell align="left" className={styles.tableHeaderNarrow}>ID</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Start date</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Interval</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Status</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Unit amount</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Currency</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Current billing date</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Next billing date</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Service type</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Voucher ID</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Parking Location</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Created at</TableCell>
                  <TableCell align="left" className={styles.tableHeader}>Updated at</TableCell>
                </TableRow>
              </StickyTableHead>
              <TableBody>
                {
                  state.subscriptions.length > 0 ? (
                    state.subscriptions.map((subscription) => (
                      <TableRow 
                        key={subscription.id}
                        hover
                        onClick={() => dispatch(setSelectedId(state.selectedId === subscription.id ? null : subscription.id))}
                        role="checkbox"
                        aria-checked={state.selectedId === subscription.id ? true : false}
                        selected={state.selectedId === subscription.id ? true : false}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox 
                            color="primary"
                            checked={state.selectedId === subscription.id ? true : false}
                          />
                        </TableCell>
                        <TableCell align="left" className={styles.tableCellNarrow}>{subscription.id}</TableCell>
                        <TableCell align="left">{subscription.startDate}</TableCell>
                        <TableCell align="left">{subscription.interval}</TableCell>
                        <TableCell align="left">{subscription.status}</TableCell>
                        <TableCell align="left">{centToDollar(subscription.unitAmount)}</TableCell>
                        <TableCell align="left">{subscription.currency}</TableCell>
                        <TableCell align="left">{subscription.currentBillingDate}</TableCell>
                        <TableCell align="left">{subscription.nextBillingDate}</TableCell>
                        <TableCell align="left">{subscription.type}</TableCell>
                        <TableCell align="left">{subscription.voucherId}</TableCell>
                        <TableCell align="left">{subscription.metadata?.location}</TableCell>
                        <TableCell align="left">{subscription.createdAt}</TableCell>
                        <TableCell align="left">{subscription.updatedAt}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={14} align="center">
                        <p>No subscriptions</p>
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    colSpan={11}
                    count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                    rowsPerPage={state.rowsPerPage}
                    page={state.page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        {state.wallet && (
          <Paper className="col-span-12 lg:col-span-3 p-5 flex flex-col gap-4">
            {state.wallet.stripeInfo?.customerId && (
              <div>
                <p className="text-sm font-medium">Stripe Customer ID</p>
                <a href={`https://dashboard.stripe.com/customers/${state.wallet.stripeInfo.customerId}`} target="_blank" rel="noreferrer" className="text-sm text-blue-500 font-medium">{state.wallet.stripeInfo.customerId}</a>
              </div>
            )}
            <div>
              <p className="text-sm font-medium">Balance amount</p>
              <p className="text-sm text-slate-500 font-medium">RM {centToDollar(state.wallet.balanceAmount)}</p>
            </div>
            <div>
              <p className="text-sm font-medium">Holding amount</p>
              <p className="text-sm text-slate-500 font-medium">RM {centToDollar(state.wallet.holdingAmount)}</p>
            </div>
            <div>
              <p className="text-sm font-medium">Total refunded amount</p>
              <p className="text-sm text-slate-500 font-medium">RM {centToDollar(state.wallet.totalRefundedAmount)}</p>
            </div>
            <div>
              <p className="text-sm font-medium">Total spent amount</p>
              <p className="text-sm text-slate-500 font-medium">RM {centToDollar(state.wallet.totalSpentAmount)}</p>
            </div>
            <div>
              <p className="text-sm font-medium">Total top up amount</p>
              <p className="text-sm text-slate-500 font-medium">RM {centToDollar(state.wallet.totalTopUpAmount)}</p>
            </div>
          </Paper>
        )}
      </div>
    </div>
  );
}
