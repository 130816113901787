import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow, TableSortLabel, Button, Paper, Checkbox, TablePagination, TableFooter, debounce, InputBase, IconButton } from "@mui/material";
import styles from './Listing.module.css';
import Moment from 'moment';
import { StickyTableHead } from '../../../helpers/GeneralComponent';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';

import ParkitNav from '../../../components/ParkitNav/ParkitNav';
import EditListingModal from './EditListingModal';
import FreezeListingModal from './FreezeListingModal';
import UnfreezeListingModal from './UnfreezeListingModal';
import CreateAppointmentModal from './CreateAppointmentModal';
import { TableToolbar } from './TableToolbar';
import TablePaginationActions from './TablePaginationActions';
import { showFilterModal, setSearchValue, handleGetListings, selectState, setSelectedId, setPage, setRowsPerPage, setSort, setSortBy, setErrorMessage, setSuccessMessage, setSelectedListing, showRentingModal } from './listingSlice';
import { selectState as selectParkitNav } from '../../../components/ParkitNav/parkitNavSlice';
import useToasts from '../../../hooks/useToasts';
import { centToDollar } from '../../../app/utils';
import { BusinessType } from '../../../app/constants';
import ViewRentingModal from './ViewRentingModal';
import FilterListingModal from './FilterListingModal';

export function Listing() {
  const state = useSelector(selectState);
  const parkitNavState = useSelector(selectParkitNav);
  const dispatch = useDispatch();
  const { toastError, toastSuccess } = useToasts();

  useEffect(() => {
    dispatch(handleGetListings());
  }, [state.sort, state.sortBy, state.selectedFilter, dispatch]);

  useEffect(() => {
    if (state.successMessage !== "") {
      toastSuccess(state.successMessage);
      dispatch(setSuccessMessage(""));
    }
  }, [dispatch, toastSuccess, state.successMessage]);

  useEffect(() => {
    if (state.errorMessage !== "") {
      toastError(state.errorMessage);
      dispatch(setErrorMessage(""));
    }
  }, [dispatch, toastError, state.errorMessage]);

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetListings());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetListings());
  };

  const handleRequestSort = (property) => {
    const isDescending = state.sortBy === property && state.sort === 'desc';
    dispatch(setSort(isDescending ? 'asc' : 'desc'));
    dispatch(setSortBy(property));
  };

  const isB2B = (businessType) => {
    switch (businessType) {
      case BusinessType.BUSINESS_TO_BUSINESS:
        return "Yes";
      case BusinessType.BUSINESS_TO_CONSUMER:
        return "No";
      default:
        return "";
    }
  };

  const onSearchValueChange = (e) => {
    const cleanValue = e.target.value.replace(/\+/g, '');
    dispatch(setPage(0));
    dispatch(setSearchValue(cleanValue));
    dispatch(handleGetListings());
  }

  const debouncedOnSearchValueChange = debounce(onSearchValueChange, 1000);

  return (
    <div className={`${parkitNavState.open ? 'ml-60' : 'ml-12'}`}>
      <ParkitNav />
      <div className="p-10 text-left">
        <Paper
          component="form"
          sx={{ p: '2px 4px', mb: '10px', display: 'flex', alignItems: 'center' }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search..."
            inputProps={{ 'aria-label': 'search', 'className': 'focus:shadow-none focus:ring-0' }}
            onChange={debouncedOnSearchValueChange}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => dispatch(showFilterModal(true))}>
            <FilterListIcon />
          </IconButton>
        </Paper>
        <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <Table size="small">
            <StickyTableHead>
              <TableRow>
                <TableCell colSpan={26} sx={{ padding: 0, borderBottom: 'none' }}>
                  <TableToolbar
                    numSelected={state.selectedId === null ? 0 : 1}
                    title="Parking Inventory"
                    actionables={state.listings.filter(r => r.id === state.selectedId)[0]?.actionables}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Bay Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Parking Area</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Unit Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Parking Area Active</TableCell>
                <TableCell align="left" className={styles.tableHeader}>
                  <TableSortLabel
                    active={state.sortBy === 'status'}
                    direction={state.sortBy === 'status' ? state.sort : 'desc'}
                    onClick={() => handleRequestSort('status')}
                  >
                    Owner Status
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader}>Driver Info</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Remarks</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Currency</TableCell>
                <TableCell align="left" className={styles.tableHeader}>
                  <TableSortLabel
                    active={state.sortBy === 'buyPrices.monthly'}
                    direction={state.sortBy === 'buyPrices.monthly' ? state.sort : 'desc'}
                    onClick={() => handleRequestSort('buyPrices.monthly')}
                  >
                    Buy Price Per Month
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader}>Handling Fees (%)</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Is Dedicated Space</TableCell>
                <TableCell align="left" className={styles.tableHeader}>
                  <TableSortLabel
                    active={state.sortBy === 'termination.customerDueDate'}
                    direction={state.sortBy === 'termination.customerDueDate' ? state.sort : 'desc'}
                    onClick={() => handleRequestSort('termination.customerDueDate')}
                  >
                    Owner Termination Date
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader}>B2B Bay</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Owner Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Owner Email</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Owner HP</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Building Type</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Tandem / Single</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Lease Period</TableCell>
                <TableCell align="left" className={styles.tableHeader}>RFID Serial Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Card Serial Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Payment Remark</TableCell>
                <TableCell align="left" className={styles.tableHeader250}>Address</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Listing ID</TableCell>
                <TableCell align="left" className={styles.tableHeader110}>
                 <TableSortLabel
                    active={state.sortBy === 'createdAt'}
                    direction={state.sortBy === 'createdAt' ? state.sort : 'desc'}
                    onClick={() => handleRequestSort('createdAt')}
                  >
                    Created At
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.listings.length > 0 ? (
                  state.listings.map((listing) => (
                    <TableRow
                      key={listing.id}
                      hover
                      onClick={() => { dispatch(setSelectedId(state.selectedId === listing.id ? null : listing.id)); dispatch(setSelectedListing(state.selectedId === listing.id ? null : listing)) }}
                      role="checkbox"
                      aria-checked={state.selectedId === listing.id ? true : false}
                      selected={state.selectedId === listing.id ? true : false}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={state.selectedId === listing.id ? true : false}
                        />
                      </TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.parkingBay?.bayNumber}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.parkingArea?.name}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.parkingBay?.unitNumber}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.parkingArea?.active ? "YES" : "NO"}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.status}</TableCell>
                      <TableCell align="center" className={styles.tableCell}>{listing.rentingId ? <Button variant="text" size="small" onClick={() => dispatch(showRentingModal(true))} sx={{ textTransform: "none" }} disabled={state.selectedId === listing.id}>View</Button> : "N/A"}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.additionalInformation?.remark}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.requestedMonthlyBuyPriceCurrency}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{centToDollar(listing.buyPrices?.monthly)}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.additionalInformation?.handlingFees ? centToDollar(listing.additionalInformation?.handlingFees) : ""}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.parkingBay?.dedicatedSpace ? "YES" : "NO"}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.termination?.customerDueDate}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{isB2B(listing.additionalInformation?.businessType)}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{`${listing.customer.user.firstName} ${listing.customer.user.lastName}`}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.customer.user.userName}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.customer.user.contactNumber}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.parkingBay?.type}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.parkingBay?.tandem ? "Tandem" : "Single"}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.additionalInformation?.leasePeriod}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.parkingBay?.rfidNumber}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.parkingBay?.cardSerialNumber}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.paymentRemark}</TableCell>
                      <TableCell align="left" className={styles.tableCell250}>{listing.parkingBay?.address}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.id}</TableCell>
                      <TableCell align="left" className={styles.tableCell110}>{Moment(listing.createdAt).format('YYYY-MM-DD HH:mm')}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={25} align="center">
                      <p>No parking bays.</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100, 500, 1000]}
                  colSpan={4}
                  count={state.pagination?.totalElements ?? 0}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      {state.editModalOpen && <EditListingModal />}
      {state.freezeModalOpen && <FreezeListingModal />}
      {state.unfreezeModalOpen && <UnfreezeListingModal />}
      {state.createAppointmentModalOpen && <CreateAppointmentModal />}
      {state.viewRentingModalOpen && <ViewRentingModal />}
      {state.filterModalOpen && <FilterListingModal />}
    </div>
  );
}