import React, { useEffect } from 'react';
import { Button } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Checkbox, TablePagination, TableFooter, Fab } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import styles from './AffiliateCampaign.module.css';
import { StickyTableHead } from '../../../helpers/GeneralComponent';

import ParkitNav from '../../../components/ParkitNav/ParkitNav';
import AddCampaignModal from './AddCampaignModal';
import EditCampaignModal from './EditCampaignModal';
import ViewPromoCodeModal from './ViewPromoCodeModal';
import GenerateAffiliateLinkModal from './GenerateAffiliateLinkModal';
import { TableToolbar } from './TableToolbar';
import TablePaginationActions from './TablePaginationActions';
import { handleGetAffiliateCampaigns, selectState, setPage, setRowsPerPage, setSelectedCampaign, setSelectedId, showAddModal, showViewPromoCodeModal, setSuccessMessage, setErrorMessage } from './affiliateCampaignSlice';
import { selectState as selectParkitNav } from '../../../components/ParkitNav/parkitNavSlice';
import useToasts from '../../../hooks/useToasts';

export function AffiliateCampaign() {
  const state = useSelector(selectState);
  const parkitNavState = useSelector(selectParkitNav);
  const dispatch = useDispatch();
  const { toastError, toastSuccess } = useToasts();

  const commissionOnDisplayName = {
    'monthlySellPrice': 'Monthly Sell Price',
    'monthlyBuyPrice': 'Monthly Buy Price',
    'insuranceSellPrice': 'Insurance Sell Price'
  };

  const commissionTypeDisplayName = {
    'oneOff': 'One-off'
  };

  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetAffiliateCampaigns());
    }
  });

  useEffect(() => {
    if (state.successMessage !== "") {
      toastSuccess(state.successMessage);
      dispatch(setSuccessMessage(""));
    }
  }, [dispatch, toastSuccess, state.successMessage]);

  useEffect(() => {
    if (state.errorMessage !== "") {
      toastError(state.errorMessage);
      dispatch(setErrorMessage(""));
    }
  }, [dispatch, toastError, state.errorMessage]);

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetAffiliateCampaigns());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetAffiliateCampaigns());
  };

  return (
    <div className={`${parkitNavState.open ? 'ml-60' : 'ml-12'}`}>
      <ParkitNav />
      <div className="fixed bottom-6 right-6" onClick={() => dispatch(showAddModal(true))}>
        <Fab color="primary" aria-label="add">
          <AddIcon />
        </Fab>
      </div>
      <div className="p-10 text-left">
        <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <Table size="small">
            <StickyTableHead>
              <TableRow>
                <TableCell colSpan={11} sx={{ padding: 0, borderBottom: 'none' }}>
                  <TableToolbar numSelected={state.selectedId === null ? 0 : 1} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Campaign Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>ID</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Start Date</TableCell>
                <TableCell align="left" className={styles.tableHeader}>End Date</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Commission %</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Commission On</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Commission Type</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Referral window (days)</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Promo code</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Validation on Parking Area</TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.affiliateCampaigns.length > 0 ? (
                  state.affiliateCampaigns.map((campaign) => (
                    <TableRow
                      key={campaign.id}
                      hover
                      onClick={() => { dispatch(setSelectedId(state.selectedId === campaign.id ? null : campaign.id)); dispatch(setSelectedCampaign(state.selectedId === campaign.id ? null : campaign)) }}
                      role="checkbox"
                      aria-checked={state.selectedId === campaign.id ? true : false}
                      selected={state.selectedId === campaign.id ? true : false}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={state.selectedId === campaign.id ? true : false}
                        />
                      </TableCell>
                      <TableCell align="left" className={styles.tableCell}>{campaign.name}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{campaign.id}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{campaign.startDate}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{campaign.endDate}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{campaign.refereeCommission}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{commissionOnDisplayName[campaign.refereeCommissionOn]}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{commissionTypeDisplayName[campaign.refereeCommissionType]}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{campaign.referableWindow}</TableCell>
                      <TableCell align="left" className={styles.tableCell}><Button variant="text" size="small" onClick={() => dispatch(showViewPromoCodeModal(true))} sx={{ textTransform: "none" }} disabled={state.selectedId === campaign.id}>View</Button></TableCell>
                      <TableCell align="left" className={styles.tableCell}>{campaign.specificParkingArea ? "Yes" : "No"}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={27} align="center">
                      <p>No affiliate campaign.</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={4}
                  count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      {state.addModalOpen && <AddCampaignModal />}
      {state.editModalOpen && <EditCampaignModal />}
      {state.viewPromoCodeModalOpen && <ViewPromoCodeModal />}
      {state.generateAffiliateLinkModalOpen && <GenerateAffiliateLinkModal />}
    </div>
  );
}