import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Checkbox, TablePagination, TableFooter } from "@mui/material";
import styles from './DeliveryRate.module.css';
import { StickyTableHead } from '../../../helpers/GeneralComponent';

import ParkitNav from '../../../components/ParkitNav/ParkitNav';
import EditRateModal from './EditRateModal';
import { TableToolbar } from './TableToolbar';
import TablePaginationActions from './TablePaginationActions';
import { handleGetRates, selectState, setPage, setRowsPerPage, setSelectedRate, setSelectedId, setSuccessMessage, setErrorMessage } from './deliveryRateSlice';
import { selectState as selectParkitNav } from '../../../components/ParkitNav/parkitNavSlice';
import useToasts from '../../../hooks/useToasts';

export function DeliveryRate() {
  const state = useSelector(selectState);
  const parkitNavState = useSelector(selectParkitNav);
  const dispatch = useDispatch();
  const { toastError, toastSuccess } = useToasts();

  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetRates());
    }
  });

  useEffect(() => {
    if (state.successMessage !== "") {
      toastSuccess(state.successMessage);
      dispatch(setSuccessMessage(""));
    }
  }, [dispatch, toastSuccess, state.successMessage]);

  useEffect(() => {
    if (state.errorMessage !== "") {
      toastError(state.errorMessage);
      dispatch(setErrorMessage(""));
    }
  }, [dispatch, toastError, state.errorMessage]);

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetRates());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetRates());
  };

  return (
    <div className={`${parkitNavState.open ? 'ml-60' : 'ml-12'}`}>
      <ParkitNav />
      <div className="p-10 text-left">
        <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <Table size="small">
            <StickyTableHead>
              <TableRow>
                <TableCell colSpan={7} sx={{ padding: 0, borderBottom: 'none' }}>
                  <TableToolbar
                    numSelected={state.selectedId === null ? 0 : 1}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Base</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Min</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Tier 1</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Tier 2</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Next</TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.deliveryRates.length > 0 ? (
                  state.deliveryRates.map((rate) => (
                    <TableRow
                      key={rate.id}
                      hover
                      onClick={() => { dispatch(setSelectedId(state.selectedId === rate.id ? null : rate.id)); dispatch(setSelectedRate(state.selectedId === rate.id ? null : rate)) }}
                      role="checkbox"
                      aria-checked={state.selectedId === rate.id ? true : false}
                      selected={state.selectedId === rate.id ? true : false}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={state.selectedId === rate.id ? true : false}
                        />
                      </TableCell>
                      <TableCell align="left" className={styles.tableCell}>{rate.name}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{rate.base}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{rate.min}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{rate.tier1}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{rate.tier2}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{rate.next}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={27} align="center">
                      <p>No delivery rates.</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={4}
                  count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      {state.editModalOpen && <EditRateModal />}
    </div>
  );
}