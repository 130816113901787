import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Checkbox, TablePagination, TableFooter } from "@mui/material";
import { TableToolbar } from './TableToolbar';
import { handleGetWallets, selectState, setSelectedId, setPage, setRowsPerPage } from './walletSlice';
import TablePaginationActions from './TablePaginationActions';
import styles from './Wallet.module.css';
import { centToDollar } from '../../app/utils';
import { StickyTableHead } from '../../helpers/GeneralComponent';

export function Wallet(props) {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetWallets());
    }
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetWallets());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetWallets());
  };

  return (
    <div className={styles.container}>
      <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
        <Table size="small">
          <StickyTableHead>
            <TableRow>
              <TableCell colSpan={4} sx={{ padding: 0, borderBottom: 'none' }}>
                <TableToolbar 
                  numSelected={state.selectedId === null ? 0 : 1}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>ID</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>User ID</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Balance Amount</TableCell>
            </TableRow>
          </StickyTableHead>
          <TableBody>
            {
              state.wallets.length > 0 ? (
                state.wallets.map((wallet) => (
                  <TableRow 
                    key={wallet.id}
                    hover
                    onClick={() => dispatch(setSelectedId(state.selectedId === wallet.id ? null : wallet.id))}
                    role="checkbox"
                    aria-checked={state.selectedId === wallet.id ? true : false}
                    selected={state.selectedId === wallet.id ? true : false}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox 
                        color="primary"
                        checked={state.selectedId === wallet.id ? true : false}
                      />
                    </TableCell>
                    <TableCell align="left" className={styles.tableCellNarrow}>{wallet.id}</TableCell>
                    <TableCell align="left">{wallet.userId}</TableCell>
                    <TableCell align="left">{centToDollar(wallet.balanceAmount)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    <p>No Wallets</p>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={11}
                count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                rowsPerPage={state.rowsPerPage}
                page={state.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}
