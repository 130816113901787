import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Checkbox, TablePagination, TableFooter } from "@mui/material";
import { TableToolbar } from './TableToolbar';
import ParkitNav from '../../../components/ParkitNav/ParkitNav';
import { handleGetUsers, selectState, setSelectedId, setSelectedUser, setPage, setRowsPerPage, showAdminTopUpModal, showWalletTransactionModal, setSelectedWalletId, setSuccessMessage, setErrorMessage } from './userSlice';
import { selectState as selectParkitNav } from '../../../components/ParkitNav/parkitNavSlice';
import useToasts from '../../../hooks/useToasts';
import TablePaginationActions from './TablePaginationActions';
import styles from './User.module.css';
import { WalletTransactions } from '../../wallet/WalletTransactions';
import { AdminTopUp } from './AdminTopUp';
import { centToDollar } from '../../../app/utils';
import EditUserModal from './EditUserModal';
import { StickyTableHead } from '../../../helpers/GeneralComponent';

export function User() {
  const state = useSelector(selectState);
  const parkitNavState = useSelector(selectParkitNav);
  const dispatch = useDispatch();
  const { toastError, toastSuccess } = useToasts();
  
  useEffect(() => {
      dispatch(handleGetUsers());
  }, [state.sort, state.sortBy, dispatch]);

  useEffect(() => {
    if (state.successMessage !== "") {
      toastSuccess(state.successMessage);
      dispatch(setSuccessMessage(""));
    }
  }, [dispatch, toastSuccess, state.successMessage]);

  useEffect(() => {
    if (state.errorMessage !== "") {
      toastError(state.errorMessage);
      dispatch(setErrorMessage(""));
    }
  }, [dispatch, toastError, state.errorMessage]);

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetUsers());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetUsers());
  };

  return (
    <div className={`${parkitNavState.open ? 'ml-60' : 'ml-12'}`}>
      <ParkitNav />
      <div className={styles.container}>
        <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <Table size="small">
            <StickyTableHead>
              <TableRow>
                <TableCell colSpan={23} sx={{ padding: 0, borderBottom: 'none' }}>
                  <TableToolbar 
                    selectedId={state.selectedId}
                    numSelected={state.selectedId === null ? 0 : 1}
                    adminTopUp={() => dispatch(showAdminTopUpModal(true))}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Email</TableCell>
                <TableCell align="left" className={styles.tableHeader}>First Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Last Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>HP</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Company</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Wallet Balance</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Birthday</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Bank Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Account Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Account Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Vehicle</TableCell>
                <TableCell align="left" className={styles.tableHeader}>IC Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Passport Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Occupation</TableCell>
                <TableCell align="left" className={styles.tableHeader}>User Type</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Active Parking</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Active Listing</TableCell>
                <TableCell align="left" className={styles.tableHeader}>B2B Owner</TableCell>
                <TableCell align="left" className={styles.tableHeaderNarrow}>ID</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Insurance Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>User's Rating</TableCell>
                <TableCell align="left" className={styles.tableHeader}>User's Review</TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.users.length > 0 ? (
                  state.users.map((subscriber) => (
                    <TableRow 
                      key={subscriber.id}
                      hover
                      onClick={() => { dispatch(setSelectedId(state.selectedId === subscriber.id ? null : subscriber.id)); dispatch(setSelectedUser(state.selectedId === subscriber.id ? null : subscriber)) }}
                      role="checkbox"
                      aria-checked={state.selectedId === subscriber.id ? true : false}
                      selected={state.selectedId === subscriber.id ? true : false}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox 
                          color="primary"
                          checked={state.selectedId === subscriber.id ? true : false}
                        />
                      </TableCell>
                      <TableCell align="left">{subscriber.user?.userName}</TableCell>
                      <TableCell align="left">{subscriber.user?.firstName}</TableCell>
                      <TableCell align="left">{subscriber.user?.lastName}</TableCell>
                      <TableCell align="left">{subscriber.user?.contactNumber}</TableCell>
                      <TableCell align="left">{subscriber.user?.company}</TableCell>
                      <TableCell align="left"
                        onClick={() => {
                          dispatch(setSelectedWalletId(subscriber.user?.wallet?.id))
                          dispatch(showWalletTransactionModal(true))
                        }}
                        style={{cursor: "pointer"}}
                      >{centToDollar(subscriber.user?.wallet?.balanceAmount || 0)}</TableCell>
                      <TableCell align="left">{subscriber.user?.birthDay}</TableCell>
                      <TableCell align="left">{subscriber.user?.wallet?.bank?.name}</TableCell>
                      <TableCell align="left">{subscriber.user?.wallet?.bank?.accountNumber}</TableCell>
                      <TableCell align="left">{subscriber.user?.wallet?.bank?.accountName}</TableCell>
                      <TableCell align="left">{subscriber.vehicles?.length > 0 ? `${subscriber.vehicles[0].registrationNumber} (${subscriber.vehicles[0].color} ${subscriber.vehicles[0].model})` : ""}</TableCell>
                      <TableCell align="left">{subscriber.identificationCard}</TableCell>
                      <TableCell align="left">{subscriber.passportNumber}</TableCell>
                      <TableCell align="left">{subscriber.occupation}</TableCell>
                      <TableCell align="left">{subscriber.userType}</TableCell>
                      <TableCell align="left">{subscriber.activeParking}</TableCell>
                      <TableCell align="left">{subscriber.activeListing}</TableCell>
                      <TableCell align="left">{subscriber.b2b}</TableCell>
                      <TableCell align="left" className={styles.tableCellNarrow}>{subscriber.id}</TableCell>
                      <TableCell align="left">{subscriber.insurance?.status}</TableCell>
                      <TableCell align="left">{subscriber.review?.recommendRating}</TableCell>
                      <TableCell align="left">{subscriber.review?.additionalThought}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={11} align="center">
                      <p>No Users</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={11}
                  count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        {state.editUserModalOpen && <EditUserModal />}
        {
          state.adminTopUpModalOpen &&
          <AdminTopUp selectedUserId={state.selectedId} />
        }
        {
          state.walletTransactionModalOpen &&
          <WalletTransactions walletId={state.selectedWalletId} closeModal={() => dispatch(showWalletTransactionModal(false))} user={state.selectedId ? state.users.find(user => user.id === state.selectedId) : null} />
        }
      </div>
    </div>
  );
}
