import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as Api from "./subscriptionAPI";
import * as WalletApi from "../../api/walletAPI";
import * as UserApi from "../user/userAPI";

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    user: null,
    wallet: null,
    subscriptions: [],
    status: 'idle',
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null,
  },
  reducers: {
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setSubscriptions: (state, action) => {
      state.subscriptions = action.payload;
      state.status = 'success'
    },
    setWallet: (state, action) => {
      state.wallet = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setSelectedId, setPage, setRowsPerPage, setPagination, setSubscriptions, setWallet, setUser } = subscriptionSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.voucher.value)`
export const selectState = state => state.subscription;

export const handleGetSubscriptions = (userId) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const user = await UserApi.getUser(userId);
    const wallet = await WalletApi.getUserWallet(userId);
    const subscriptionRes = await Api.getSubscriptions(wallet.id, state.subscription.page, state.subscription.rowsPerPage);
    const { content, ...pagination } = subscriptionRes;
    
    dispatch(setUser(user));
    dispatch(setWallet(wallet));
    dispatch(setSubscriptions(content));
    dispatch(setPagination(pagination));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};


export default subscriptionSlice.reducer;
