import { Toolbar, Typography } from "@mui/material";

export const TableToolbar = (props) => {
  const { numSelected } = props;
  return (
    <Toolbar>
      {numSelected > 0 ? (
        <Typography color="inherit" variant="subtitle1" component="div" style={{ flex: 1 }}>
          {numSelected} selected
        </Typography>
      ) : (
        <div>
          <Typography variant="h6" id="tableTitle" component="div">
            Subscriptions
          </Typography>
        </div>
      )}
    </Toolbar>
  );
};