import { API_BASE_URL } from "../../app/utils";
import { getJwtToken } from "../../app/common";

export const getSubscriptions = (walletId, page, size) =>
  fetch(`${API_BASE_URL}/wallet/v1/wallets/${walletId}/subscriptions?page=${page}&size=${size}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());
